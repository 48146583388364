import { IMultilingual, Language, IPropsStore } from '@wix/thunderbolt-symbols'
import { LanguageSelectorSiteConfig, ItemFormat, IconType, DisplayMode, LanguageSelectorPageConfig } from './types'
import { utils } from '@wix/santa-multilingual/dist/languages' // eslint-disable-line no-restricted-syntax
import { LanguageSelectorItem, LanguageSelectorProps } from '@wix/thunderbolt-components'

const calculateItemText = (language: Language, itemFormat: ItemFormat, displayMode: DisplayMode, isMobile: boolean) => {
	if (itemFormat === 'shortName') {
		return language.languageCode.toUpperCase()
	} else if (itemFormat === 'fullName') {
		return language.name
	} else if (itemFormat === 'fullLocalizedName') {
		return language.localizedName
	} else if (itemFormat === 'iconOnly' && displayMode !== 'horizontal' && isMobile) {
		return language.name
	}
}

export const getItems = (
	siteFeatureConfig: LanguageSelectorSiteConfig,
	iconType: IconType,
	itemFormat: ItemFormat,
	languagesOrder: Array<string>,
	displayMode: DisplayMode,
	isMobile: boolean,
	multilingual?: IMultilingual
): Array<LanguageSelectorItem> => {
	if (!multilingual) {
		return []
	}
	const sortByOriginalLanguage = (language: LanguageSelectorItem) =>
		language.value === siteFeatureConfig.originalLanguageCode ? -1 : 1

	const { flagsUrl } = siteFeatureConfig

	const languages = multilingual.siteLanguages
		.map((language) => {
			const hasIcons = iconType && iconType !== 'none'
			return {
				text: calculateItemText(language, itemFormat, displayMode, isMobile),
				value: language.languageCode,
				selected: language.languageCode === multilingual.currentLanguage.languageCode,
				label: language.name,
				flag: hasIcons ? utils.getFlagIconPng(flagsUrl, iconType, language.countryCode) : undefined,
				flagSrcSet: hasIcons ? utils.getFlagIconSrcSet(flagsUrl, iconType, language.countryCode) : undefined,
			}
		})
		.sort((language1, language2) => {
			const result = languagesOrder.length
				? languagesOrder.indexOf(language1.value) - languagesOrder.indexOf(language2.value)
				: sortByOriginalLanguage(language1)

			return result
		})

	return languages
}

export const updateProps = ({
	siteFeatureConfig,
	componentsMetaData,
	props,
	isMobile,
	multilingual,
}: {
	siteFeatureConfig: LanguageSelectorSiteConfig
	componentsMetaData: LanguageSelectorPageConfig['componentsMetaData']
	props: IPropsStore
	isMobile: boolean
	multilingual?: IMultilingual
}) => {
	const newProps = componentsMetaData.reduce<Record<string, Partial<LanguageSelectorProps>>>(
		(acc, { id, iconType, itemFormat, languagesOrder, displayMode }) => {
			acc[id] = {
				items: getItems(
					siteFeatureConfig,
					iconType,
					itemFormat,
					languagesOrder,
					displayMode,
					isMobile,
					multilingual
				),
			}
			return acc
		},
		{}
	)
	props.update(newProps)
}
